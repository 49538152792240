import { useState } from "react";

const Header = ({onSSClicked}) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className="header-area header-sticky navbar navbar-expand-lg navbar-light bg-light p-3">
      <div className="container">
        <a className="navbar-brand" href="/">
          <h3><img src="logo.png" height={60} alt="" /></h3>
        </a>
        <button className="navbar-toggler" type="button" onClick={toggleNav}>
          {isNavOpen ? <i className="ti-close"/> : <i className="ti-menu"/>}
        </button>
        <div className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="#hero">
                Acceuil
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                À propos
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#rooms">
                Chambres
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#gastronomie">
                Gastronomie
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#conference-room">
                Salle de conférences
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link glow-text" href="#" onClick={onSSClicked} style={{color: "red"}}>
                Saint-Sylvestre
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                Contact
              </a>
            </li>
            <li className="nav-item">
              <a style={{color: "#dca73a"}} className="nav-link" href="/reservation">
                Réservez <i className="ti-calendar"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
