import React, { useEffect, useState } from "react";
import AppModal from "../components/app-modal";
import axios from "axios";
import { APP_URL } from "../config/constants";
import { redirect } from "react-router-dom";
export default function SaintSylvestre() {
  // return redirect("/")
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [numberOfPersons, setNumberOfPersons] = useState(1);
  const [numberOfChildren, setNumberOfChildren] = useState(0);
  const [showPaiementModal, setShowPaiementModal] = useState(false);
  const [clientInfo, setClientInfo] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
  });
  const [paiementLinkLoading, setPaiementLinkLoading] = useState(false);
  const choices = [
    { id: 1, label: "Diner gala seulement", price: 175, childrenPrice: 110 },
    { id: 2, label: "Pack 1 nuit: Hébérgement en LPD + soirée", price: 230 },
    { id: 3, label: "Pack 2 nuits: Hébérgement en DP + soirée", price: 305 },
    { id: 4, label: "Pack 3 nuits: Hébérgement en DP + soirée", price: 385 },
  ];

  const handleChoiceChange = (choiceId) => {
    setSelectedChoice(choiceId);
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth", // Use 'auto' for instant scrolling
    });
  };

  const handlePersonsChange = (e) => {
    setNumberOfPersons(parseInt(e.target.value, 10));
  };

  const handleChildrenChange = (e) => {
    setNumberOfChildren(parseInt(e.target.value, 10));
  };

  const handleInputChange = (field, value) => {
    setClientInfo((prevClientInfo) => ({ ...prevClientInfo, [field]: value }));
  };

  const calculateTotalAmount = () => {
    if (selectedChoice !== null) {
      const choice = choices.find((choice) => choice.id === selectedChoice);

      if (choice.childrenPrice)
        return (
          choice.childrenPrice * numberOfChildren +
          choice.price * numberOfPersons
        );
      return choice.price * (numberOfPersons + numberOfChildren);
    }
    return 0;
  };

  const onPaiementClicked = (e) => {
    e.preventDefault();

    setPaiementLinkLoading(true);
    const data = {
      selectedChoice,
      numberOfPersons,
      numberOfChildren,
      clientInfo,
      totalAmount: calculateTotalAmount(),
      list: choices,
    };

    const env = process.env.NODE_ENV;
    axios
      .post(
        env === "production"
          ? "https://api.konnect.network/api/v2/payments/init-payment"
          : "https://api.preprod.konnect.network/api/v2/payments/init-payment",
        {
          receiverWalletId:
            env === "production"
              ? "64d204df4760b71467dc3764"
              : "6496da5f1331dd46cb7455f5",
          token: "TND",
          amount: (data.totalAmount * 1000 * 1.023).toFixed(3),
          type: "immediate",
          lifespan: 10,
          addPaymentFeesToAmount: false,
          firstName: data.clientInfo.name,
          lastName: "",
          phoneNumber: data.clientInfo.phone,
          email: data.clientInfo.email,
          orderId: "",
          webhook: APP_URL + "confirmation-paiement-reservation-ss",
          silentWebhook: false,
          checkoutForm: true,
          acceptedPaymentMethods: ["bank_card", "e-DINAR"],
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key":
              env === "production"
                ? "64d204df4760b71467dc3761:H3CyvSzafNZ0AbuEQbhU"
                : "6496da5f1331dd46cb7455f2:PxCtiPHlnaVidhaXR5Ea06z2mcyAA",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setPaiementLinkLoading(false);
          localStorage.setItem("reservation_ss", JSON.stringify(data));
          window.open(res.data.payUrl, "_blank");
        } else {
          console.log(res.data);
        }
      })
      .catch((error) => {
        setPaiementLinkLoading(false);

        console.log(error);
        alert(error.response.data.errors[0].message);
      });
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    function handleMessage(event) {
      if (event.data === "reloadPage") {
        window.location.reload();
      }
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-4">
          <img
            src="/assets/img/saint-sylvestre.jpg"
            alt=""
            width="100%"
            height="auto"
          />
        </div>
        <div className="col-12 col-md-8">
          <h3 className="mt-2 border-bottom">
            Soyez les bienvenus chez hôtel Nesrine Hammamet
          </h3>

          <div className="mt-4">
            <h5>Choix de Réservation</h5>
            <div className="form-check">
              {choices.map((choice) => (
                <div key={choice.id} className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="reservationChoice"
                    id={`choice${choice.id}`}
                    value={choice.id}
                    checked={selectedChoice === choice.id}
                    onChange={() => handleChoiceChange(choice.id)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`choice${choice.id}`}
                  >
                    {choice.label} - {choice.price}DT/ Personne ***{" "}
                    {choice.childrenPrice
                      ? choice.childrenPrice + "DT/ Enfant (4 à 11.99 ans)"
                      : ""}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-4">
            <label htmlFor="numberOfPersons">Nombre de Personnes:</label>
            <input
              className="form-control"
              id="numberOfPersons"
              value={numberOfPersons}
              onChange={handlePersonsChange}
              type="number"
              step={1}
            />
          </div>
          <div className="mt-4">
            <label htmlFor="numberOfChildren">Nombre d'Enfants:</label>
            <input
              className="form-control"
              id="numberOfChildren"
              value={numberOfChildren}
              onChange={handleChildrenChange}
              type="number"
              step={1}
            />
          </div>

          <div className="mt-4">
            <h5>Total à Payer</h5>
            <p>
              {calculateTotalAmount().toFixed(3)} DT +{" "}
              <small>
                (frais de paiement en ligne:{" "}
                {((calculateTotalAmount() * 2.3) / 100).toFixed(3)})
              </small>
            </p>
            <button
              style={{ marginBottom: 82 }}
              disabled={calculateTotalAmount() === 0}
              onClick={() => setShowPaiementModal(true)}
              className="btn btn-primary"
            >
              Réserver
            </button>
          </div>
        </div>
      </div>
      {showPaiementModal && (
        <AppModal
          modalTitle="Veuillez remplir ce formulaire"
          content={
            <form onSubmit={onPaiementClicked}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nom et Prénom (*):
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="name"
                  value={clientInfo.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Adresse Email (*):
                </label>
                <input
                  required
                  type="email"
                  className="form-control"
                  id="email"
                  value={clientInfo.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Adresse (*):
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="address"
                  value={clientInfo.address}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Numéro de Téléphone (*):
                </label>
                <input
                  required
                  type="tel"
                  className="form-control"
                  id="phone"
                  value={clientInfo.phone}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                />
              </div>

              <button
                disabled={paiementLinkLoading}
                className="btn btn-primary"
              >
                {paiementLinkLoading ? "Chargement..." : "Passer au paiement"}
              </button>
            </form>
          }
          onCloseClicked={() => setShowPaiementModal(false)}
        />
      )}
    </div>
  );
}
